module.exports = new Promise(function fetchRemoteA(resolve){
  // We define a script tag to use the browser for fetching the remoteEntry.js file
  const script = document.createElement("script");
  script.src = 'https://goataglanceprodeunsa.blob.core.windows.net/gomfataglance/app/remoteEntry.js'; // This could be defined anywhere
  // When the script is loaded we need to resolve the promise back to Module Federation
  script.onload = () => {
    // The script is now loaded on window using the name defined within the remote
    const module = {
      get: (request) => window.GLANCE_APP.get(request),
      init: (arg) => {
        try {
          return window.GLANCE_APP.init(arg);
        } catch (e) {
          console.log("Remote A has already been loaded");
        }
      },
    };
    resolve(module);
    }
    
  
  // Lastly we inject the script tag into the document's head to trigger the script load
  document.head.appendChild(script);
});