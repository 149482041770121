import('./bootstrap');
// import React, { StrictMode } from 'react';
// import { createRoot } from 'react-dom/client';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { BrowserRouter as Router } from 'react-router-dom';
// import Auth0ProviderWithHistory from './pages/auth/auth0-provider-with-history';
// import { NotificationContainer } from 'react-notifications';
// import { Provider } from "react-redux";
// import './index.css';
// import 'react-notifications/lib/notifications.css';
// import './assets/css/mandatoryModal.css';
// import './i18next';

// import initializeStore from './store/'; // Access redux store data
// const isServer = typeof window === 'undefined';
// const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

// function getOrCreateStore(initialState) {
//   // Always make a new store if server, otherwise state is shared between requests
//   if (isServer) {
//     return initializeStore;
//   }

//   // Create store if unavailable on the client and set it on the window object
//   if (!window[__NEXT_REDUX_STORE__]) {
//     window[__NEXT_REDUX_STORE__] = initializeStore;
//   }
//   return window[__NEXT_REDUX_STORE__];
// }

// const reduxStore = getOrCreateStore();

// const rootElement = document.getElementById('root');
// const root = createRoot(rootElement);

// root.render(
//   // <StrictMode>
//     <Router>
//       <Auth0ProviderWithHistory>
//         <Provider store={reduxStore}>
//           <App />
//           <NotificationContainer />
//         </Provider>
//       </Auth0ProviderWithHistory>
//     </Router>
//   // </StrictMode>
//   ,
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();



